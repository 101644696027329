import cookie from '../plugins/jquery.cookie.js';
import Headroom from '../plugins/headroom.js';

jQuery(function($) {
  // Sticky Header
  var myElement = document.querySelector(".global-header");
  var headroom  = new Headroom(myElement, {
    "offset": 150,
    "tolerance": 0
  });
  headroom.init();

  // Anchor
  $('a').on('click', function(e){
    var $href = $(this).attr('href');
    if ($href.match('^#') && !$(this).hasClass('accordion-tab')) {
      e.preventDefault();
      $('html, body').stop().animate({
          scrollTop: $($href).offset().top
      });
    }
  });

  // Video popups
  $('a').on('click', function(e){
    var $target = $(this).attr('href');
    if ($target.toLowerCase().indexOf("vimeo") >= 0) {
      e.preventDefault();
      var $n = $target.lastIndexOf('/');
      var $vid = $target.substring($n + 1);
      $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      $('.video-popup-wrapper').addClass('active');
      $('body, html').addClass('body-noscroll');
    }
    if ($target.toLowerCase().indexOf("youtu") >= 0) {
      e.preventDefault();
      var $y = $target.lastIndexOf('=');
      var $yid = $target.substring($y + 1);
      $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      $('.video-popup-wrapper').addClass('active');
      $('body, html').addClass('body-noscroll');
    }
  });
  $('.close-popup').on('click', function(){
    $('.video-popup-wrapper').removeClass('active');
    $('.popup-content').html('');
    $('body, html').removeClass('body-noscroll');
  });

  // Mobile Nav
  $('#mobile-nav-toggle').on('click', function(){
    var $navheight = $('#mobile-navigation').height() + 170;
    $('body, html').toggleClass('nav-locked');
    $('#mobile-navigation-wrapper').toggleClass('active');
    $(this).toggleClass('active');
  });

  // Resize for Match-Height and Slick-Slider glitch fix
  scroll_actions();
  $(window).bind('load', function(){
    $(window).trigger('resize');
    setTimeout(function(){ $(window).trigger('resize'); }, 500);
    scroll_actions();
  });
  $(document).ready(scroll_actions());
  $(window).scroll(scroll_actions());

  function scroll_actions() {
    $('.loading').each(function(){
      $(this).addClass('loaded');
    });
    $('.animate').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 100);
      }
    });
    $('.animate-1').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 100);
      }
    });
    $('.animate-2').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 200);
      }
    });
    $('.animate-3').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 300);
      }
    });
    $('.animate-4').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 400);
      }
    });
    $('.animate-5').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 500);
      }
    });
    $('.animate-6').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 600);
      }
    });
    $('.animate-7').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 700);
      }
    });
    $('.animate-8').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 800);
      }
    });
    $('.animate-9').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 900);
      }
    });
    $('.animate-10').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1000);
      }
    });
    $('.animate-11').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1100);
      }
    });
    $('.animate-12').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1200);
      }
    });
    $('.animate-13').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1300);
      }
    });
    $('.animate-14').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1400);
      }
    });
    $('.animate-15').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1500);
      }
    });
    $('.animate-16').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1600);
      }
    });
    $('.animate-17').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1700);
      }
    });
    $('.animate-18').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1800);
      }
    });
    $('.animate-19').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 1900);
      }
    });
    $('.animate-20').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2000);
      }
    });
    $('.animate-21').each(function(){
      var $this = $(this);
      if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
        setTimeout(function(){
          $this.addClass('visible');
        }, 2100);
      }
    });
  }

});